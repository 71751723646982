import * as React from 'react'
import { Link } from 'gatsby'

const Footer = () => {
    return (
        <footer id="footer">
            Copyright &copy; {new Date().getFullYear()} Educa'dog asbl,  Tous droits réservés | <Link to={'/reglement-interieur'}>Règlement intérieur du centre</Link>
        </footer>
    )
}

export default Footer