import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Logo from '../images/logo-educadog-light-white.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

const TopBar = () => {
    const [isMenuVisible, setMenuVisible] = useState(false)

    useEffect(() => {
        document.getElementById('menu').querySelector('.menu-button').setAttribute('data-show', isMenuVisible)
    })

    return (
        <nav id="menu">
            <div className="container">
                <div className="background">
                    <Link to={'/'} className="logo" alt="Page d'accueil">
                        <img src={Logo} alt="Logo Educadog" />
                    </Link>
                    <ul data-show={isMenuVisible}>
                        <li>
                            <Link to={'/'} className="nav-link" activeClassName={"active"}>
                                Accueil
                            </Link>
                        </li>
                        <li>
                            <Link to={'/le-centre/'} className="nav-link" activeClassName={"active"}>
                                Le centre
                            </Link>
                        </li>
                        <li>
                            <Link to={'/l-equipe/'} className="nav-link" activeClassName={"active"}>
                                L'équipe
                            </Link>
                        </li>
                        <li>
                            <Link to={'/reglement-interieur/'} className="nav-link" activeClassName={"active"}>
                                Règlement intérieur
                            </Link>
                        </li>
                        <li>
                            <Link to={'/photos/'} className="nav-link" activeClassName={"active"}>
                                Photos
                            </Link>
                        </li>
                        <li>
                            <Link to={'/inscription-aux-cours/'} className="nav-link" activeClassName={"active"}>
                                Inscription aux cours
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={'/agenda/'} className="nav-link" activeClassName={"active"}>
                                Agenda
                            </Link>
                        </li> */}
                        {/* <li>
                            <Link to={'/faq/'} className="nav-link" activeClassName={"active"}>
                                FAQ
                            </Link>
                        </li> */}
                    </ul>
                    <Link to={'/devenir-membre'} className="register-button button primary" data-show={isMenuVisible}>
                        Devenir membre
                    </Link>
                    <button className="menu-button" data-show={isMenuVisible} href="#" onClick={() => setMenuVisible(!isMenuVisible)}>
                        <FontAwesomeIcon icon={faBars} className="show" alt="Ouvrir le menu" />
                        <FontAwesomeIcon icon={faTimes} className="hide" alt="Fermer le menu" />
                    </button>
                    <button className="close-menu-button" data-show={isMenuVisible} onClick={() => setMenuVisible(!isMenuVisible)}>
                        <FontAwesomeIcon icon={faTimes} alt="Fermer le menu" />
                    </button>
                </div>
            </div>
        </nav>
    )
}

export default TopBar