import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

const TopBar = () => {
    return (
        <section id="top-bar">
            <div className="container">
            <h1 className="slogan">
                Bienvenue chez Educa'dog !
            </h1>
            <a className="contact-us" href="mailto:info@educadog.be;">
                <FontAwesomeIcon icon={faEnvelope} alt="Nous envoyer un email" /> info@educadog.be
            </a>
            <div className="social-networks">
                <h2>Suivez-nous</h2>
                <ul>
                    <li>
                        <a href="https://www.facebook.com/EducaDogLeglise/" target="_blank" rel="noopener" title="Lien vers notre page Facebook">
                            <FontAwesomeIcon icon={faFacebook} alt="Notre page Facebook" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/educadog.leglise/" target="_blank" rel="noopener" title="Lien vers notre page Instagram">
                            <FontAwesomeIcon icon={faInstagram} alt="Notre page Instagram" />
                        </a>
                    </li>
                </ul>
            </div>
            </div>
        </section>
    )
}

export default TopBar