import React from 'react'
import { Helmet } from 'react-helmet'

import TopBar from './top-bar'
import Menu from './menu'
import Footer from './footer'

import '../css/app.css'

const Layout = ({ children }) => {
    return (
        <>
            <Helmet htmlAttributes={{ lang: 'fr' }}>
                <meta name="google-site-verification" content="VwjgXVTQ5b3Jh58fVr6YiAWTJpGKCaA6_pw7AF3ec7c" />
            </Helmet>
            <header id="header">
                <TopBar></TopBar>
                <Menu></Menu>
            </header>
            <main id="main">
                <div className="main-container">
                    {children}
                </div>
            </main>
            <Footer></Footer>
        </>
    )
}

export default Layout